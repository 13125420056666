import GeneralAnnouncementIcon from "./DigitalMediaIcons/GeneralAnnouncementIcon";
import ReviewIcon from "./DigitalMediaIcons/ReviewIcon";
import PhotoIcon from "./DigitalMediaIcons/PhotoIcon";
import DynamicInput from "../../../uiwrappers/GenericDynamicInput/GenericDynamicInput";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import { groupPhotosByGallery } from "../../editor/utils";
import GenericBadge from "../../../uiwrappers/GenericBadge/GenericBadge";
import BeforeAfterIcon from "./DigitalMediaIcons/BeforeAfterIcon";
import BlogIcon from "./DigitalMediaIcons/BlogIcon";
import PromotionalIcon from "./DigitalMediaIcons/PromotionalIcon";
import ServiceIcon from "./DigitalMediaIcons/ServiceIcon";
import TeamSpotlightIcon from "./DigitalMediaIcons/TeamSpotlightIcon";
import { CalendarIcon } from "@heroicons/react/solid";
import { getFormatedDate } from "../../reviews/ReviewContainer/utils";
import { api } from "../../../../helpers/topline-api";

export const topics = [
  {
    long_name: "General Announcement",
    short_name: "announcement",
    display_name: "Announcement",
    subheader: "Share exciting business updates",
  },
  {
    long_name: "Share a ‘Before & After’ photo",
    short_name: "before_after",
    display_name: "Before & After",
    subheader: "Emphasize the results of your work",
  },
  {
    long_name: "Share a Blog",
    short_name: "blog",
    display_name: "Blog",
    subheader: "Increase brand engagement",
  },
  {
    long_name: "Run a Promotional",
    short_name: "promo",
    display_name: "Promotional",
    subheader: "Reach new and existing customers",
  },
  {
    long_name: "Share a Review",
    short_name: "review",
    display_name: "Review",
    subheader: "Establish trust and build rapport",
  },
  {
    long_name: "Share a Service",
    short_name: "service",
    display_name: "Service",
    subheader: "Promote and highlight a service",
  },
  {
    long_name: "Share a Photo",
    short_name: "photo",
    display_name: "Photo",
    subheader: "Showcase your recent work",
  },
  {
    long_name: "Team Spotlight",
    short_name: "spotlight",
    display_name: "Team Spotlight",
    subheader: "Establish trust and build rapport",
  },
];

export async function getSocialVideos(slug: string) {
  try {
    const response = await api.get(`/api/get-rendered-social-videos`);
    return response.data.message;
  } catch (e) {
    console.log("error", e);
    return;
  }
}

export async function getTemplateHtml(slug: string, data: any) {
  try {
    const response = await api.post(
      `/api/digital-asset-get-html/${slug}`,
      data
    );
    return response.data;
  } catch (e) {
    console.log("error", e);
    return;
  }
}

export async function triggerInitialVideoCreation(slug: string) {
  const response = await api.post(`/api/create-initial-social-videos`);
  return response.data;
}

export async function getAllTemplateHtml(slug: string, data: any) {
  try {
    const response = await api.post(
      `/api/digital-asset-get-all-html-by-topic/${slug}`,
      data
    );
    return response.data;
  } catch (e) {
    console.log("error", e);
    return [];
  }
}

export async function htmlToImage(slug: string, data: any) {
  const response = await api.post(
    `/api/digital-asset-html-to-image/${slug}`,
    data
  );

  return response?.data?.message;
}

export async function fetchNewImage(slug: string) {
  try {
    const response = await api.get(`/api/digital-asset-fetch-latest/${slug}`);
    return response?.data?.message;
  } catch (e) {
    console.log("error", e);
    return "";
  }
}

export function executeHtmlScripts(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const scripts = doc.querySelectorAll("script");

  scripts.forEach((script) => {
    if (script.src && script.src.includes("https://cdn.tailwindcss.com")) {
      return;
    }

    const existingScript = document.querySelector(
      `script[src="${script.src}"]`
    );
    if (existingScript && existingScript.getAttribute("data-executed")) {
      return;
    }

    const newScript = document.createElement("script");
    if (script.src) {
      newScript.src = script.src;
    } else {
      newScript.innerHTML = `(function() { ${script.innerHTML} })();`;
    }

    if (existingScript) {
      existingScript.remove();
    }

    newScript.setAttribute("data-executed", "true");
    document.body.appendChild(newScript);
  });
}

export function scaleDownHtml() {
  const container = document.getElementById("container");
  const parent = container?.parentElement;

  if (container && parent) {
    // Scale down the container
    container.style.transform = "scale(0.5)";
    container.style.transformOrigin = "top left";

    // Calculate the offset needed to center the container
    const offsetX = (parent.offsetWidth - container.offsetWidth * 0.5) / 2;
    const offsetY = (parent.offsetHeight - container.offsetHeight * 0.5) / 2;

    // Apply the offset
    container.style.position = "relative";
    container.style.left = `${offsetX}px`;
    container.style.top = `${offsetY}px`;
  }
}

export function renderBlogTile(blog: any) {
  if (blog) {
    return (
      <div
        className={`flex relative w-full border border-gray-300 rounded-lg bg-white mb-4`}
      >
        <div className="p-4 w-full">
          <div className="flex flex-col gap-2 text-sm-normal">
            <div className="flex flex-col gap-1">
              <div className="flex justify-between text-gray-500">
                <span>{getBlogTimeMarker(blog)}</span>
                {blog?.time_to_post && (
                  <div className="flex items-center gap-1">
                    <CalendarIcon className="w-4 h-4" />
                    <span className="text-gray-600">Scheduled</span>
                  </div>
                )}
              </div>
              <h3 className="text-h3-semibold">{blog.title}</h3>
            </div>
            <div className="text-gray-700">{getFormattedBlogBody(blog)}</div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}

const getFormattedTimeString = (timeString: string) => {
  const date = new Date(timeString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  return `${getFormatedDate(timeString)} at ${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${amPm}`;
};

export const getBlogTimeMarker = (blog: any) => {
  if (blog?.time_to_post) {
    return getFormattedTimeString(blog.time_to_post);
  } else {
    return getFormattedTimeString(blog.created_at);
  }
};

export const getFormattedBlogBody = (blog: any) => {
  const regex = /(<([^>]+)>)/gi;
  let formattedBody = blog?.body.replace(regex, "");
  if (formattedBody.length > 200) {
    formattedBody = formattedBody.slice(0, 200) + "...";
  }
  return formattedBody;
};

export async function digitalAssetGenerateText(
  purpose: string,
  slug: string,
  templateContext: any
) {
  try {
    const response = await api.post(`/api/digital-asset-generate-text`, {
      purpose,
      slug,
      templateContext,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return "";
  }
}

export async function digitalAssetRewriteText(
  purpose: string,
  slug: string,
  templateContext: any,
  currentValue: string
) {
  try {
    const { headline } = templateContext || {};
    const response = await api.post(`/api/digital-asset-rewrite-text`, {
      purpose,
      slug,
      headline,
      currentValue,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return "";
  }
}

export function renderTextInputs(
  templateContext: any,
  handleChange: (_name: string, _value: string) => void,
  generateText: (_purpose: string) => void,
  rewriteText: (_purpose: string, _currentValue: string) => void,
  phoneNumber: string
): React.ReactElement {
  return (
    <div className="flex flex-col space-y-6">
      <div>
        <DynamicInput
          name="headline"
          value={templateContext?.headline}
          label="Headline"
          edit={true}
          handleLocalEdits={(e) => handleChange("headline", e["headline"])}
          placeHolder="Get your FREE Quote today!"
        />
        <div className="flex space-x-4">
          <PrimaryLink
            onClickFunc={() => generateText("headline")}
            text="Generate"
            size="md"
            icon="chat-alt"
          />
          <PrimaryLink
            onClickFunc={() =>
              rewriteText("headline", templateContext?.headline)
            }
            text="Rewrite for me"
            size="md"
            icon="sparkles"
          />
        </div>
      </div>
      <div>
        <DynamicInput
          name="subheading"
          value={templateContext?.subheading}
          label="Subheading"
          edit={true}
          handleLocalEdits={(e) => handleChange("subheading", e["subheading"])}
          placeHolder="Get your FREE Quote today!"
        />
        <div className="flex space-x-4">
          <PrimaryLink
            onClickFunc={() => generateText("subheading")}
            text="Generate"
            size="md"
            icon="chat-alt"
          />
          <PrimaryLink
            onClickFunc={() =>
              rewriteText("subheading", templateContext?.subheading)
            }
            text="Rewrite for me"
            size="md"
            icon="sparkles"
          />
        </div>
      </div>
      <div>
        <DynamicInput
          name="cta"
          value={templateContext?.cta}
          label="Call to action"
          edit={true}
          handleLocalEdits={(e) => handleChange("cta", e["cta"])}
          placeHolder={`Text or call ${phoneNumber}`}
        />
        <div className="flex space-x-4">
          <PrimaryLink
            onClickFunc={() => generateText("cta")}
            text="Generate"
            size="md"
            icon="chat-alt"
          />
          <PrimaryLink
            onClickFunc={() => rewriteText("cta", templateContext?.cta)}
            text="Rewrite for me"
            size="md"
            icon="sparkles"
          />
        </div>
      </div>

      <div>
        <GenericBadge
          icon="information"
          iconPlacement="leading"
          color="gray"
          text={getFontBadgeText()}
        />
      </div>
    </div>
  );
}

export function getIcon(topic: any) {
  const topicName = topic?.short_name;
  switch (topicName) {
    case "announcement":
      return <GeneralAnnouncementIcon />;
    case "review":
      return <ReviewIcon />;
    case "photo":
      return <PhotoIcon />;
    case "before_after":
      return <BeforeAfterIcon />;
    case "blog":
      return <BlogIcon />;
    case "promo":
      return <PromotionalIcon />;
    case "service":
      return <ServiceIcon />;
    case "spotlight":
      return <TeamSpotlightIcon />;
  }
}

export function getFormattedTemplateData(templateContext: any) {
  const data = {
    topic: templateContext?.topic,
    photo: templateContext?.photo,
    primary_color: templateContext?.primary_color,
    secondary_color: templateContext?.secondary_color,
    tertiary_color: templateContext?.tertiary_color,
    font: templateContext?.font,
    headline: templateContext?.headline,
    subheading: templateContext?.subheading,
    cta: templateContext?.cta,
    template_version: templateContext?.template_version,
    review_id: templateContext?.review_id,
    blog_id: templateContext?.blog_id,
    scheduled_blog_id: templateContext?.scheduled_blog_id,
    service_id: templateContext?.service_id,
    teammate_id: templateContext?.teammate_id,
  };
  return data;
}

export function getAllPhotos(userPhoto, userGalleries, second_last_login_time) {
  const photos = userPhoto;
  const galleries = userGalleries?.message?.galleries;

  const formattedImagesByGallery = groupPhotosByGallery(
    photos,
    galleries,
    second_last_login_time
  );
  return formattedImagesByGallery;
}

export function getAllGalleries(userGalleries) {
  if (!userGalleries) return;

  const allPhotosGallery = {
    "gallery-name": "All Photos",
    "gallery-subtitle": "",
  } as any;

  return [allPhotosGallery, ...userGalleries.message.galleries];
}

export async function downloadImage(url: string, filename: string) {
  const response = await fetch(url, {
    cache: "no-store",
  });
  const blob = await response.blob();
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function deleteDigitalMedia(media: any) {
  try {
    const id = media?.id;
    const response = await api.post(`/api/digital-asset-delete`, { id });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return "";
  }
}

export function getTabsInitialState(topic: string) {
  return topic === "review"
    ? tabsInitialState.filter((tab) => tab.id !== "text")
    : [...tabsInitialState];
}

export const tabsInitialState = [
  {
    id: "media",
    name: "Media",
    selected: true,
    scrollAmount: 202,
  },
  {
    id: "text",
    name: "Text",
    selected: false,
    scrollAmount: 335,
  },
  {
    id: "color",
    name: "Color",
    selected: false,
    scrollAmount: 752,
  },
  {
    id: "layout",
    name: "Layouts",
    selected: false,
    scrollAmount: 1000,
    badge: "new",
  },
];

export function setTabsOnScroll(tabs, setTabs) {
  const currentScrollY = window.scrollY;
  let selectedTab = tabs[0];

  for (let i = 0; i < tabs.length - 1; i++) {
    const halfwayPoint = (tabs[i].scrollAmount + tabs[i + 1].scrollAmount) / 2;
    if (currentScrollY >= halfwayPoint) {
      selectedTab = tabs[i + 1];
    } else {
      break;
    }
  }

  let newTabs = tabs.map((tab) => ({
    ...tab,
    selected: tab.id === selectedTab.id,
  }));

  if (isScrolledToBottom()) {
    newTabs = tabs.map((tab, index) => ({
      ...tab,
      selected: index === tabs.length - 1,
    }));
  }

  setTabs(newTabs);
}

function isScrolledToBottom() {
  const windowHeight = window.innerHeight;
  const currentScroll = window.scrollY;
  const documentHeight = document.documentElement.scrollHeight;

  return currentScroll + windowHeight >= documentHeight;
}

export async function getHtmlFromId(id, slug) {
  try {
    const response = await api.post(
      `/api/digital-asset-get-html-from-id/${slug}`,
      { id }
    );
    return response.data;
  } catch (e) {
    console.log("error:", e);
    return "";
  }
}

export async function getMediaObject(id) {
  try {
    const response = await api.post(`/api/digital-asset-get-media-obj`, { id });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return "";
  }
}

export function getWebsitePalette(basicInfo) {
  const defaultColors = [
    basicInfo?.custom_primary,
    basicInfo?.custom_secondary,
    "#ffffff",
  ];
  return defaultColors;
}

export function getDigitalMediaPalette(basicInfo, digitalMediaDefaults) {
  const defaultColors = [
    digitalMediaDefaults?.primary_color || basicInfo?.custom_primary,
    digitalMediaDefaults?.secondary_color || basicInfo?.custom_secondary,
    digitalMediaDefaults?.tertiary_color || "#ffffff",
  ];
  return defaultColors;
}

export function getRandomTopics(topics, numberOfTopics, topicsToExclude = []) {
  const filteredTopics = topics.filter(
    (topic, index) => !topicsToExclude.includes(index)
  );
  const shuffled = filteredTopics.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, numberOfTopics);
}

export function getOneRandomTopic(
  userPhoto,
  reviewInfo,
  userPersonalBlogs,
  userScheduleBlogs,
  basicInfo
) {
  const filteredTopics = getFilteredTopics(
    topics,
    userPhoto,
    reviewInfo,
    userPersonalBlogs,
    userScheduleBlogs,
    basicInfo
  );

  const randomTopic = getRandomTopics(filteredTopics, 1);
  if (randomTopic?.length) {
    return randomTopic[0].short_name;
  } else {
    return "photo";
  }
}

export function getFilteredTopics(
  topics,
  userPhoto,
  reviewInfo,
  userPersonalBlogs,
  userScheduleBlogs,
  basicInfo
) {
  if (!userPhoto?.some((photo: any) => photo?.before_after)) {
    topics = topics.filter((t: any) => t.short_name !== "before_after");
  }

  if (
    !reviewInfo?.filter((review: any) => review.status === "approved").length
  ) {
    topics = topics.filter((t: any) => t.short_name !== "review");
  }

  if (!userPersonalBlogs?.blogs?.length && !userScheduleBlogs?.length) {
    topics = topics.filter((t: any) => t.short_name !== "blog");
  }

  const allServices = basicInfo?.services;
  const hasNoServicePhotos =
    allServices?.length > 0 &&
    !allServices?.some((service: any) => service?.photos?.length > 0);

  if (!allServices?.length || hasNoServicePhotos) {
    topics = topics.filter((t: any) => t.short_name !== "service");
  }

  const teammates = basicInfo?.teammates;
  const hasNoTeammateNames =
    teammates?.length > 0 &&
    !teammates?.some((teammate: any) => !!teammate?.name);

  if (!basicInfo?.teammates?.length || hasNoTeammateNames) {
    topics = topics.filter((t: any) => t.short_name !== "spotlight");
  }

  return topics;
}

export function getMarginOffset(editContainerHeight: number) {
  let marginOffset = 0;

  if (editContainerHeight > 750) {
    marginOffset = 120;
  } else if (editContainerHeight > 700) {
    marginOffset = 100;
  } else if (editContainerHeight > 650) {
    marginOffset = 80;
  } else if (editContainerHeight > 600) {
    marginOffset = 60;
  } else if (editContainerHeight > 550) {
    marginOffset = 40;
  } else if (editContainerHeight > 500) {
    marginOffset = 30;
  } else if (editContainerHeight > 450) {
    marginOffset = 20;
  } else {
    marginOffset = 10;
  }

  return marginOffset;
}

export function loadImageAsync(imagePath: string) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imagePath;
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (error) => {
      reject(error);
    };
  });
}

function parseFontFaces(css: string) {
  const fontData = [];

  // Use a regular expression to find all the @font-face rules in the CSS
  const fontFaceRules = css.match(/@font-face\s*{[^}]*}/g);

  if (fontFaceRules) {
    for (const rule of fontFaceRules) {
      const fontInfo = {};
      const weightMatch = /font-weight:\s*(\d+);/.exec(rule);
      const urlMatch = /src:\s*url\(['"]?([^'"\)]+)['"]?\)/i.exec(rule);
      const unicodeRangeMatch = /unicode-range:\s*([^;]+);/.exec(rule);

      if (weightMatch && urlMatch) {
        fontInfo.weight = parseInt(weightMatch[1]);
        fontInfo.url = urlMatch[1];
        fontInfo.unicodeRange = unicodeRangeMatch ? unicodeRangeMatch[1] : "";
        fontData.push(fontInfo);
      }
    }
  }

  return fontData;
}

export async function loadFont(fontName: string, fontURL: string) {
  try {
    const response = await fetch(fontURL);
    if (response.ok) {
      const data = await response.text();
      const fontsArray = parseFontFaces(data).map((data) => {
        const fontFace = new FontFace(fontName, `url(${data.url})`, {
          style: "normal",
          weight: data.weight,
          unicodeRange: data.unicodeRange,
          display: "swap",
        });
        document.fonts.add(fontFace);
        return fontFace;
      });
      return Promise.all(fontsArray.map((fontFace) => fontFace.load()))
        .then((loadedFonts) => {
          console.log("All font faces are loaded:", loadedFonts);
        })
        .catch((error) => {
          console.error("Error loading fonts:", error);
        });
    } else {
      throw new Error(
        `Failed to load fonts: ${response.status} - ${response.statusText}`
      );
    }
  } catch (error) {
    console.error("An error occurred while loading the font:", error);
  }
}
